<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->

        <h2 class="brand-text text-primary ml-1">
          Ongphra Wallpaper Creator
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img :src="imgUrl" fluid alt="Register V2" style="max-height: 80vh;" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            เปลี่ยนรหัสผ่าน 🔒
          </b-card-title>
          <b-card-text class="mb-2"> *โปรดระบุรหัสผ่านใหม่ของบัญชี {{ userEmail }} </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form class="auth-reset-password-form mt-2" method="POST" @submit.prevent="submitResetPassword">
              <!-- password -->
              <b-form-group label="รหัสผ่านใหม่" label-for="reset-password-new">
                <validation-provider #default="{ errors }" name="รหัสผ่านใหม่" vid="Password" rules="required|min:8">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="••••••••"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group label-for="reset-password-confirm" label="ยืนยันรหัสผ่าน">
                <validation-provider #default="{ errors }" name="ยืนยันรหัสผ่าน" rules="required|confirmed:Password">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="reset-password-confirm"
                      placeholder="••••••••"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-overlay :show="btnLoading" spinner-small>
                <b-button block type="submit" variant="primary">
                  เปลี่ยนรหัสผ่าน
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login' }"> <feather-icon icon="ChevronLeftIcon" /> กลับไปเข้าสู่ระบบ </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>

    <modal-otp-widget
      ref="refModalOtp"
      :ref-code="otpRef"
      :loading="otpLoading"
      custom-message-header-body="ทางเราได้ส่ง OTP เพื่อเปลี่ยนรหัสผ่านไปที่เบอร์มือถือที่ได้ลงทะเบียนกับอีเมลแล้ว"
      @confirm="sandOtpConfirmResetPassword"
    />
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'

import { required } from '@validations'
import store from '@/store/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      otpRef: '',
      otpLoading: false,
      btnLoading: false,
      sideImg: require('@/assets/images/ongphra/reset_password_light.svg'),
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/ongphra/reset_password_dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.userEmail = this.$route.query.email
    if (this.$route.query.token) return

    this.gDisplayToast('พบข้อผิดพลาด', 'โปรดขอเปลี่ยนรหัสผ่านอีกครั้ง', 'danger')
    this.$router.push({ name: 'auth-forgot-password', replace: true })
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    // validationForm() {
    //   this.$refs.simpleRules.validate().then(success => {
    //     if (success) {
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Form Submitted',
    //           icon: 'EditIcon',
    //           variant: 'success',
    //         },
    //       })
    //     }
    //   })
    // },
    async submitResetPassword() {
      const validate = await this.$refs.simpleRules.validate()
      if (!validate) return

      const { token } = this.$route.query

      if (!token) {
        this.gDisplayToast('พบข้อผิดพลาด', 'โปรดขอเปลี่ยนรหัสผ่านอีกครั้ง', 'danger')
        this.$router.push({ name: 'auth-forgot-password', replace: true })
        return
      }

      this.btnLoading = true
      const resp = await this.api.postV2('api/partners/forgot-password/request-sms', { token }, this)
      this.btnLoading = false
      // console.log('submitResetPassword', resp)

      if (!resp) return

      if (resp.code === 200) {
        this.otpRef = `${resp.data.ref}`
        this.$refs.refModalOtp.show()
        return
      }

      if (resp.code === 405 || resp.code === 406) {
        this.gDisplayToast('ไม่สามารถเปลี่ยนรหัสผ่านได้', resp?.data?.message, 'danger')
        this.$router.push({ name: 'auth-forgot-password', replace: true })
      }
    },
    async sandOtpConfirmResetPassword(otp) {
      const { token, email } = this.$route.query

      if (!token || !email) {
        this.$refs.refModalOtp.hide()
        this.gDisplayToast('พบข้อผิดพลาด', 'โปรดขอเปลี่ยนรหัสผ่านอีกครั้ง', 'danger')
        this.$router.push({ name: 'auth-forgot-password', replace: true })
        return
      }

      const requestData = {
        password: this.password,
        password_confirmation: this.cPassword,
        otp_ref: this.otpRef,
        otp: Number(otp),
        email,
        token,
      }

      this.otpLoading = true
      const resp = await this.api.postV2('api/partners/forgot-password/confirm-password-to-change', requestData, this)
      // console.log('sandOtpConfirmResetPassword', resp)
      this.otpLoading = false

      if (!resp) return

      if (resp.code === 200) {
        this.gDisplayToast('เปลี่ยนรหัสผ่านสำเร็จ', 'สามารถเข้าใช้งานด้วยรหัสผ่านใหม่ได้แล้ว')
        this.$router.push({ name: 'auth-login', replace: true })
        return
      }

      if (resp.code === 406) {
        this.gDisplayToast('เปลี่ยนรหัสผ่านไม่สำเร็จ', resp.data?.message, 'danger')
        this.$refs.refModalOtp.hide()
        return
      }

      if (resp.code === 405) {
        this.gDisplayToast('เปลี่ยนรหัสผ่านไม่สำเร็จ', resp.data?.message, 'danger')
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
